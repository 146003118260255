import { createElement } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import FullScreenCenteredLayout from "./FullScreenCenteredLayout";

function EmptyState({ icon, text }) {
  const theme = useTheme();

  return (
    <FullScreenCenteredLayout>
      {icon &&
        createElement(icon, {
          sx: { fontSize: theme.spacing(10) },
          color: "disabled",
        })}
      <Typography
        component="h1"
        variant="h4"
        color="text.disabled"
        sx={{ maxWidth: "8em" }}
        align="center"
      >
        {text}
      </Typography>
    </FullScreenCenteredLayout>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.elementType,
  text: PropTypes.node.isRequired,
};

EmptyState.defaultProps = {
  icon: null,
};

export default EmptyState;

import { useContext, useEffect } from "react";

import { LoadingStateContext } from "./LoadingStateProvider";

function LoadingSpinner() {
  const [, setLoading] = useContext(LoadingStateContext);

  useEffect(() => {
    setLoading(true);

    return () => setLoading(false);
  }, [setLoading]);

  return null;
}

export default LoadingSpinner;

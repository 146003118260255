import { createContext, useEffect, useMemo, useState } from "react";
import { getAuth } from "firebase/auth";

export const AuthContext = createContext(null);

function AuthProvider(props) {
  const auth = useMemo(getAuth, []);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, [auth]);

  return <AuthContext.Provider value={currentUser} {...props} />;
}

export default AuthProvider;

import { useContext } from "react";
import BowlingIcon from "mdi-material-ui/Bowling";
import { useTheme } from "@mui/material/styles";

import { LoadingStateContext } from "./LoadingStateProvider";

import FullScreenCenteredLayout from "./FullScreenCenteredLayout";

function WaitingRoom() {
  const theme = useTheme();
  const [loading] = useContext(LoadingStateContext);

  if (!loading) return null;

  return (
    <FullScreenCenteredLayout>
      <div className="sk-chase">
        <BowlingIcon
          color="primary"
          sx={{ fontSize: theme.spacing(4) }}
          className="sk-swing-dot"
        />
        <BowlingIcon
          color="secondary"
          sx={{ fontSize: theme.spacing(4) }}
          className="sk-swing-dot"
        />
      </div>
    </FullScreenCenteredLayout>
  );
}

export default WaitingRoom;

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import WaitingRoom from "./components/WaitingRoom";
import Router from "./Router";
import theme from "./theme";

function App() {
  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <ThemeProvider {...{ theme }}>
        <CssBaseline />
        <Router />
        <WaitingRoom />
      </ThemeProvider>
    </Box>
  );
}

export default App;

import { useState, createElement } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import MonthView from "./MonthView";
import YearView from "./YearView";

const Views = {
  MONTH: "month",
  YEAR: "year",
};

const COMPONENTS = {
  [Views.MONTH]: MonthView,
  [Views.YEAR]: YearView,
};

function Stats({ history }) {
  const [view, setView] = useState(Views.MONTH);

  return (
    <>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="back"
          sx={{ mr: 2 }}
          onClick={history.goBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <Select onChange={(e) => setView(e.target.value)} value={view}>
          <MenuItem value={Views.MONTH}>Month</MenuItem>
          <MenuItem value={Views.YEAR}>Year</MenuItem>
        </Select>
      </Toolbar>
      <Container
        sx={{ p: 2, pt: 0, display: "flex", flexDirection: "column", flex: 1 }}
      >
        {createElement(COMPONENTS[view])}
      </Container>
    </>
  );
}

Stats.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default Stats;

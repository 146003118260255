import { startOfDay } from "date-fns";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import toPairs from "lodash/toPairs";

export default function useGamesGroupedByDate(games, sortDirection = "desc") {
  return orderBy(
    toPairs(groupBy(games, (game) => startOfDay(game.date))),
    ([date]) => new Date(date),
    sortDirection
  );
}

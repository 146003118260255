import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function FullScreenCenteredLayout(props) {
  return (
    <Grid
      container={true}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Grid item={true}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          {...props}
        />
      </Grid>
    </Grid>
  );
}

export default FullScreenCenteredLayout;

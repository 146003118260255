function useFocusNextField(predicate, refs) {
  return refs.map((ref) => ({
    onChange(event) {
      if (predicate(event)) {
        ref.current.focus();
        ref.current.select();
      }
    },
  }));
}

export default useFocusNextField;

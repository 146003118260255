import camelCase from "lodash/camelCase";
import fromPairs from "lodash/fromPairs";
import maxBy from "lodash/maxBy";
import sumBy from "lodash/sumBy";

const getAverage = (games, property) => sumBy(games, property) / games.length;

function useStats(games = []) {
  return {
    ...fromPairs(
      ["score", "strikes", "spares"].map((property) => [
        camelCase(`average_${property}`),
        getAverage(games, property),
      ])
    ),
    total: sumBy(games, "score"),
    highest: maxBy(games, "score")?.score,
  };
}

export default useStats;

import { useMemo } from "react";
import PropTypes from "prop-types";
import {
  ComposedChart,
  CartesianGrid,
  Rectangle,
  YAxis,
  Bar,
  Line,
  Tooltip,
} from "recharts";
import { useTheme } from "@mui/material/styles";
import { format, getDate, getMonth, getDaysInMonth, setDate } from "date-fns";
import enGB from "date-fns/locale/en-GB";
import find from "lodash/find";
import meanBy from "lodash/meanBy";
import range from "lodash/range";
import sortBy from "lodash/sortBy";

function ScoresChart({ games, partition, ...rest }) {
  const theme = useTheme();

  const data = useMemo(() => {
    const data = games.map((game, index) => ({
      ...game,
      day: getDate(game.date),
      month: getMonth(game.date),
      runningAverage: meanBy(games.slice(0, index + 1), "score"),
    }));

    if (partition === "month") {
      range(1, getDaysInMonth(games[0].date)).forEach((day) => {
        if (!find(data, { day })) data.push({ day });
      });
    } else if (partition === "year") {
      range(0, 12).forEach((month) => {
        if (!find(data, { month })) data.push({ month });
      });
    }

    return data;
  }, [games, partition]);

  return (
    <ComposedChart
      {...{
        data: sortBy(data, partition === "month" ? "day" : "month"),
        ...rest,
      }}
    >
      <CartesianGrid vertical={false} />
      <YAxis
        dataKey="score"
        domain={[0, 300]}
        orientation="right"
        axisLine={false}
        mirror={true}
      />
      <Tooltip
        labelFormatter={(p) =>
          format(
            setDate(new Date(), p),
            partition === "month" ? "PP" : "LLLL yyyy",
            {
              locale: enGB,
            }
          )
        }
      />
      <Bar
        dataKey="score"
        stackId="scores"
        fill={theme.palette.primary.light}
        shape={<Rectangle radius={[10, 10, 0, 0]} />}
        name="Score"
      />
      <Line
        dataKey="runningAverage"
        stroke={theme.palette.secondary.main}
        strokeDasharray="5 5"
        strokeWidth={5}
        name="Average"
      />
    </ComposedChart>
  );
}

ScoresChart.propTypes = {
  // @todo dedupe prop types across components
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      strikes: PropTypes.number.isRequired,
      spares: PropTypes.number.isRequired,
      date: PropTypes.object.isRequired,
    })
  ).isRequired,
  partition: PropTypes.oneOf(["month", "year"]).isRequired,
};

ScoresChart.defaultProps = {
  partition: "month",
};

export default ScoresChart;

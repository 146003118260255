import { createContext, useState } from "react";

export const LoadingStateContext = createContext(false);

function LoadingStateProvider(props) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingStateContext.Provider value={[loading, setLoading]} {...props} />
  );
}

export default LoadingStateProvider;

import { createElement, useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { AuthContext } from "./AuthProvider";

function PrivateRoute({ component, ...rest }) {
  const user = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(renderProps) => {
        if (user === undefined) return null;

        if (!user) return <Redirect to="/login" />;

        return createElement(component, renderProps);
      }}
    />
  );
}

export default PrivateRoute;

import { forwardRef } from "react";
import PropTypes from "prop-types";
import MuiTextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";

const TextField = forwardRef(function TextField(props, ref) {
  const { name, rules, ...rest } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <MuiTextField
      name={name}
      variant="outlined"
      {...register(name, {
        valueAsNumber: props.type === "number",
        ...rules,
      })}
      error={!!error}
      helperText={error?.message}
      fullWidth={true}
      {...rest}
      inputProps={{ ...rest?.inputProps, ref }}
    />
  );
});

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  type: PropTypes.string,
};

TextField.defaultProps = {
  rules: {},
  type: undefined,
};

export default TextField;

import { useContext, useMemo } from "react";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../components/AuthProvider";
import FullScreenCenteredLayout from "../components/FullScreenCenteredLayout";

const FIREBASE_UI_CONFIG = {
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [GoogleAuthProvider.PROVIDER_ID],
};

function Login() {
  const user = useContext(AuthContext);
  const firebaseAuth = useMemo(getAuth, []);

  if (user) return <Redirect to="/" />;

  return (
    <FullScreenCenteredLayout>
      <StyledFirebaseAuth {...{ uiConfig: FIREBASE_UI_CONFIG, firebaseAuth }} />
    </FullScreenCenteredLayout>
  );
}

export default Login;

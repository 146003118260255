import { useCallback, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormProvider, useForm } from "react-hook-form";
import { getFirestore, collection, addDoc } from "firebase/firestore";

import { AuthContext } from "../components/AuthProvider";
import DateField from "../components/DateField";
import TextField from "../components/TextField";
import useFocusNextField from "../hooks/useFocusNextField";

function AddScoreForm(props) {
  const { history } = props;

  const user = useContext(AuthContext);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: { strikes: 0, spares: 0 },
  });
  const { handleSubmit } = methods;
  const [strikesInputRef, sparesInputRef] = [useRef(), useRef()];
  const [focusStrikesInput, focusSparesInput] = useFocusNextField(
    (event) => {
      const { max, value } = event.target;
      const maxLength = String(max).length;

      /* If adding another digit to the end of the value would make it go above
       * the field's max value (eg 78 + 0 = 780), or the user has inputted the
       * max number of characters */
      return (
        parseInt(value, 10) * 10 > parseInt(max, 10) ||
        value.length >= maxLength
      );
    },
    [strikesInputRef, sparesInputRef]
  );

  const onSubmit = useCallback(
    ({ score, strikes, spares, date }) => {
      const db = getFirestore();

      addDoc(collection(db, "games"), {
        userId: user.uid,
        score,
        strikes,
        spares,
        date,
      });

      history.goBack();
    },
    [user, history]
  );

  return (
    <>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="back"
          sx={{ mr: 2 }}
          onClick={history.goBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Add a score
        </Typography>
      </Toolbar>
      <Container sx={{ p: 2 }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={4}>
                <TextField
                  type="number"
                  label="Score"
                  name="score"
                  autoFocus={true}
                  inputProps={{ min: 0, max: 300 }}
                  rules={{
                    required: "Can’t be blank",
                    min: { value: 0, message: "Can’t be less than 0" },
                    max: {
                      value: 300,
                      message: "Can’t be greater than 300",
                    },
                  }}
                  {...focusStrikesInput}
                />
              </Grid>
              <Grid item={true} xs={4}>
                <TextField
                  type="number"
                  name="strikes"
                  inputProps={{ min: 0, max: 12 }}
                  label="Strikes"
                  ref={strikesInputRef}
                  {...focusSparesInput}
                />
              </Grid>
              <Grid item={true} xs={4}>
                <TextField
                  type="number"
                  name="spares"
                  inputProps={{ min: 0, max: 10 }}
                  label="Spares"
                  ref={sparesInputRef}
                />
              </Grid>
            </Grid>
            <Box
              mt={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <DateField name="date" label="Date" />
              <Box>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Container>
    </>
  );
}

AddScoreForm.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AddScoreForm;

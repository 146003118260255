import React from "react";
import ReactDOM from "react-dom";
import { initializeApp } from "firebase/app";
import {
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  enableIndexedDbPersistence,
  getFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "spinkit/spinkit.min.css";

import App from "./App";
import AuthProvider from "./components/AuthProvider";
import LoadingStateProvider from "./components/LoadingStateProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";
import "./whyDidYouRender";
//import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyC1sd26LEHS7NTHbqL6T7wxxlUnpO0tl0I",
  authDomain: "bowling-718e9.firebaseapp.com",
  projectId: "bowling-718e9",
  storageBucket: "bowling-718e9.appspot.com",
  messagingSenderId: "943308141483",
  appId: "1:943308141483:web:ae22d313768d54c14e98ee",
  measurementId: "G-YSK81RBTCR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

const db = getFirestore();

if (window.location.hostname === "localhost") {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(getAuth(), "http://localhost:9099/", {
    disableWarnings: true,
  });
}

enableIndexedDbPersistence(db).catch((err) => {
  console.log(err);
});

ReactDOM.render(
  <React.StrictMode>
    <LoadingStateProvider>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </AuthProvider>
    </LoadingStateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();

// reportWebVitals();

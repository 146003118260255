import { forwardRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";

const DateField = forwardRef(function DateField(props, ref) {
  const { name, rules, ...rest } = props;
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const watchValue = watch(name);
  const error = errors[name];

  useEffect(() => {
    register(name, rules);

    setValue(name, new Date());
  }, [name, rules, register, setValue]);

  const handleChange = useCallback(
    (newValue) => setValue(name, newValue),
    [name, setValue]
  );

  return (
    <MobileDatePicker
      name={name}
      inputFormat="dd/MM/yyyy"
      variant="outlined"
      onChange={handleChange}
      error={!!error}
      helperText={error?.message}
      disableCloseOnSelect={false}
      okText={null}
      showTodayButton={true}
      maxDate={new Date()}
      {...rest}
      renderInput={(props) => <TextField {...props} />}
      value={watchValue}
    />
  );
});

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  type: PropTypes.string,
};

DateField.defaultProps = {
  rules: {},
  type: undefined,
};

export default DateField;

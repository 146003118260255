import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import BowlingIcon from "mdi-material-ui/Bowling";
import ChartIcon from "mdi-material-ui/Finance";
import { Link } from "react-router-dom";

import BottomAppBar from "../components/BottomAppBar";
import EmptyState from "../components/EmptyState";
import LoadingSpinner from "../components/LoadingSpinner";
import GroupedGamesList from "../components/GroupedGamesList";
import useGames from "../hooks/useGames";

function Home() {
  const games = useGames();

  if (!games) return <LoadingSpinner />;

  return (
    <>
      {games?.length ? (
        <Box p={3} pb={8}>
          <GroupedGamesList {...{ games }} />
        </Box>
      ) : (
        <EmptyState icon={BowlingIcon} text="No scores recorded yet." />
      )}
      <BottomAppBar>
        <BottomAppBar.Fab
          color="secondary"
          aria-label="add"
          component={Link}
          to="/add"
        >
          <AddIcon />
        </BottomAppBar.Fab>

        <Box flexGrow={1} />

        <IconButton
          color="inherit"
          component={Link}
          to="/stats"
          aria-label="stats"
          align="end"
        >
          <ChartIcon />
        </IconButton>
      </BottomAppBar>
    </>
  );
}

export default Home;

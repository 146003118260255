import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { enGB } from "date-fns/esm/locale";

import useGamesGroupedByDate from "../hooks/useGamesGroupedByDate";

import GamesList from "./GamesList";

function GroupedGamesList(props) {
  const { games } = props;

  const grouped = useGamesGroupedByDate(games);

  return grouped.map(([date, group]) => (
    <Box mb={4} key={date}>
      <Typography variant="h5" component="h1">
        {format(new Date(date), "PPP", { locale: enGB })}
      </Typography>
      <GamesList games={group} />
    </Box>
  ));
}

GamesList.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      strikes: PropTypes.number.isRequired,
      spares: PropTypes.number.isRequired,
      date: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default GroupedGamesList;

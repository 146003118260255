import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import MuiFab from "@mui/material/Fab";
import Toolbar from "@mui/material/Toolbar";

function BottomAppBar({ children, ...rest }) {
  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0 }}
      {...rest}
    >
      <Toolbar>{children}</Toolbar>
    </AppBar>
  );
}

export function Fab({ sx, ...rest }) {
  return (
    <MuiFab
      sx={{
        position: "absolute",
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: "0 auto",
        ...sx,
      }}
      {...rest}
    />
  );
}

Fab.propTypes = {
  sx: PropTypes.object,
};

Fab.defaultProps = {
  sx: {},
};

BottomAppBar.Fab = Fab;

export default BottomAppBar;

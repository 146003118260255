import { useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { format, isSameYear, isSameMonth, add } from "date-fns";
import useDimensions from "react-cool-dimensions";

import useGames from "../../hooks/useGames";
import useStats from "../../hooks/useStats";
import EmptyState from "../../components/EmptyState";
import LoadingSpinner from "../../components/LoadingSpinner";
import ScoresChart from "../../components/ScoresChart";

function MonthView() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const games = useGames();
  const {
    observe,
    width: chartContainerWidth,
    height: chartContainerHeight,
  } = useDimensions();

  const filteredGames = useMemo(
    () =>
      games?.filter(
        ({ date }) =>
          isSameYear(selectedDate, date) && isSameMonth(selectedDate, date)
      ),
    [games, selectedDate]
  );
  const stats = useStats(filteredGames);
  const changeMonth = useCallback(
    (amount) => () => {
      setSelectedDate(
        add(selectedDate, {
          months: amount,
        })
      );
    },
    [selectedDate]
  );
  const isMaxMonth = useMemo(() => {
    const now = new Date();

    return isSameYear(selectedDate, now) && isSameMonth(selectedDate, now);
  }, [selectedDate]);
  const isMinMonth = useMemo(() => {
    const minDate = games?.[0]?.date;
    return (
      isSameYear(selectedDate, minDate) && isSameMonth(selectedDate, minDate)
    );
  }, [selectedDate, games]);

  if (!games) return <LoadingSpinner />;

  return (
    <>
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          flex={1}
          alignItems="center"
        >
          <IconButton
            color="inherit"
            aria-label="previous month"
            onClick={changeMonth(-1)}
            disabled={isMinMonth}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h5" align="center" component="h1">
            {format(selectedDate, "MMMM yyyy")}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="next month"
            onClick={changeMonth(1)}
            disabled={isMaxMonth}
            sx={isMaxMonth && { opacity: 0 }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {!filteredGames.length ? (
        <EmptyState text="Nothing to display" />
      ) : (
        <>
          <Box mt={2} display="flex" flex={1} ref={observe}>
            <ScoresChart
              {...{
                games: filteredGames,
                width: chartContainerWidth,
                height: chartContainerHeight,
              }}
            />
          </Box>

          <Box mt={1} display="flex" flex={1}>
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <Typography variant="body2" align="center">
                  Pins
                </Typography>
                <Typography variant="h5" align="center">
                  {stats.total}
                </Typography>
              </Grid>
              <Grid item={true} xs={6}>
                <Typography variant="body2" align="center">
                  Games
                </Typography>
                <Typography variant="h5" align="center">
                  {filteredGames.length}
                </Typography>
              </Grid>
              <Grid item={true} xs={6}>
                <Typography variant="body2" align="center">
                  Best
                </Typography>
                <Typography variant="h5" align="center">
                  {stats.highest}
                </Typography>
              </Grid>

              <Grid item={true} xs={6}>
                <Typography variant="body2" align="center">
                  Average
                </Typography>
                <Typography variant="h5" align="center">
                  {stats.averageScore.toFixed(1)}
                </Typography>
              </Grid>
              <Grid item={true} xs={6}>
                <Typography variant="body2" align="center">
                  Average strikes
                </Typography>
                <Typography variant="h5" align="center">
                  {stats.averageStrikes.toFixed(1)}
                </Typography>
              </Grid>

              <Grid item={true} xs={6}>
                <Typography variant="body2" align="center">
                  Average spares
                </Typography>
                <Typography variant="h5" align="center">
                  {stats.averageSpares.toFixed(1)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default MonthView;

import { useContext } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import { AuthContext } from "./components/AuthProvider";
import LoadingSpinner from "./components/LoadingSpinner";
import PrivateRoute from "./components/PrivateRoute";
import AddScoreForm from "./pages/AddScoreForm";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Stats from "./pages/Stats";

function Router() {
  const user = useContext(AuthContext);

  if (user === undefined) return <LoadingSpinner />;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />

        <PrivateRoute path="/add" component={AddScoreForm} />
        <PrivateRoute path="/stats" component={Stats} />
        <PrivateRoute path="/" component={Home} exact={true} />

        {user === null && <Redirect to="/login" />}

        {/* @todo 404 page */}
      </Switch>
    </BrowserRouter>
  );
}

export default Router;

import { useContext, useEffect, useState } from "react";
import {
  collection,
  orderBy,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";

import { AuthContext } from "../components/AuthProvider";

function useGames() {
  const user = useContext(AuthContext);
  const [games, setGames] = useState(null);

  useEffect(() => {
    if (!user) return;

    const db = getFirestore();

    const q = query(
      collection(db, "games"),
      where("userId", "==", user.uid),
      orderBy("date", "asc")
    );

    getDocs(q).then((querySnapshot) => {
      setGames(
        querySnapshot.docs.map((doc) => {
          const data = doc.data();

          return {
            id: doc.id,
            ...data,
            date: data.date.toDate(),
          };
        })
      );
    });
  }, [user]);

  return games;
}

export default useGames;

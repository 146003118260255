import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#cc2822" },
    secondary: { main: "#607d8b" },
  },
});

export default theme;

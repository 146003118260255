import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import orderBy from "lodash/orderBy";

import useStats from "../hooks/useStats";

function GamesList({ games }) {
  const stats = useStats(games);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">Score</TableCell>
          <TableCell align="center">Strikes</TableCell>
          <TableCell align="center">Spares</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderBy(games, "date", "desc").map((game) => (
          <TableRow key={game.id}>
            <TableCell align="left">{game.score}</TableCell>
            <TableCell align="center">{game.strikes}</TableCell>
            <TableCell align="center">{game.spares}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell size="small" sx={{ color: "text.secondary" }} align="left">
            {stats.averageScore.toFixed(1)}
          </TableCell>
          <TableCell
            size="small"
            sx={{ color: "text.secondary" }}
            align="center"
          >
            {stats.averageStrikes.toFixed(1)}
          </TableCell>
          <TableCell
            size="small"
            sx={{ color: "text.secondary" }}
            align="center"
          >
            {stats.averageSpares.toFixed(1)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

GamesList.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      strikes: PropTypes.number.isRequired,
      spares: PropTypes.number.isRequired,
      date: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default GamesList;
